import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import "./ScenarioDisplay.css"

import {
  LoadingIndicator,
  LoadingIndicatorPage,
  SEO,
  Modal,
  ShareLinks,
} from "../../../../components"
import { useAuthState } from "../../../Auth/state"
import { getCorrectScenarioOption } from "../../utils"

import { ScenarioOptions } from "../ScenarioOptions"
import { ScenarioHeroDisplay } from "../ScenarioHeroDisplay"
import { ScenarioResolution } from "../ScenarioResolution"
import { useScenario } from "../../hooks"
import { SubscribeCTA } from "../../../Subscribe/components"
import { CategoryBadge } from "../CategoryBadge"

interface ScenarioProps {
  scenarioId: string
}

export const ScenarioDisplay = ({ scenarioId }: ScenarioProps) => {
  const { isAnonymous } = useAuthState()
  const {
    scenarioData,
    isCreatingPrediction,
    isFetchingPrediction,
    isFetchingPredictionSpread,
    selectedOptionId,
    predictionSpread,
    prediction,
    predictionConfidence,
    onChangePredictionConfidence,
    onSelectOption,
    onMakePrediction: onCreatePrediction,
  } = useScenario(scenarioId)
  const isCreatingPredictionRef = useRef(isCreatingPrediction)

  const [willShowPrompt, setWillShowPrompt] = useState(false)

  if (!scenarioData || scenarioData.id !== scenarioId) {
    return <LoadingIndicatorPage />
  }

  const { options, resolution } = scenarioData
  const correctOption = getCorrectScenarioOption(scenarioData)

  const hasMadePrediction =
    !!selectedOptionId &&
    !isCreatingPrediction &&
    !isFetchingPrediction &&
    !isFetchingPredictionSpread

  const showSubscribePrompt = isAnonymous && hasMadePrediction && willShowPrompt

  useEffect(() => {
    if (isCreatingPredictionRef.current !== isCreatingPrediction) {
      if (!isCreatingPrediction) {
        setWillShowPrompt(true)
      }
      isCreatingPredictionRef.current = isCreatingPrediction
    }
  }, [isCreatingPrediction, isCreatingPredictionRef, setWillShowPrompt])

  return (
    <>
      <SEO title={scenarioData.title} />
      <div className="Scenario">
        <div className="ScenarioPreHeader">
          <CategoryBadge {...scenarioData.category} />
          <ShareLinks shareType="scenario" scenario={scenarioData} />
        </div>
        <ScenarioHeroDisplay scenario={scenarioData} hideCategory />
        {isFetchingPrediction || isFetchingPredictionSpread ? (
          <div className="ButtonsLoadingPlaceholder">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div className="ButtonsContainer">
              <ScenarioOptions
                options={options}
                isAnswerable={scenarioData.isAnswerable}
                resolution={resolution || null}
                prediction={prediction}
                isSubmittingPrediction={isCreatingPrediction}
                isFetchingPredictionSpread={isFetchingPredictionSpread}
                predictedOptionId={selectedOptionId}
                predictionSpread={predictionSpread}
                predictionConfidence={predictionConfidence}
                onChangePredictionConfidence={onChangePredictionConfidence}
                onSelectOption={onSelectOption}
                onCreatePrediction={onCreatePrediction}
              />
            </div>
            {!!correctOption && (
              <ScenarioResolution
                resolvedOptionId={correctOption.id}
                resolvedOptionTitle={correctOption.title}
                predictedOptionId={selectedOptionId}
                predictionSpread={predictionSpread}
              />
            )}
            <div className="Links">
              <Link to="/">Back to homepage</Link>
              {isAnonymous && (
                <>
                  |<Link to="/login">Subscribe to updates</Link>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Modal isOpen={showSubscribePrompt} shouldCloseOnOverlayClick={false}>
        <SubscribeCTA
          continueTo={`/scenario/${scenarioId}`}
          onClose={() => setWillShowPrompt(false)}
        />
      </Modal>
    </>
  )
}
