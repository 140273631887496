import { useMemo, useEffect, useCallback, useState } from "react"
import _find from "lodash/find"

import { Scenario, UserPrediction, PredictionSpread } from "../../../types.gen";
import { useScenarioState } from "../state";
import { useAuthState } from "../../../features/Auth/state";
import { ScenarioService } from "../services";

interface UseScenario {
  isFetchingScenario: boolean
  isFetchingPrediction: boolean
  isFetchingPredictionSpread: boolean
  isCreatingPrediction: boolean
  selectedOptionId: string
  scenarioData: Maybe<Scenario>
  prediction: Maybe<UserPrediction>
  predictionSpread: Maybe<PredictionSpread>
  predictionConfidence: number
  onChangePredictionConfidence: (confience: number) => void
  onSelectOption: (scenarioOptionId: Maybe<string>) => void
  onMakePrediction: () => Promise<void>
}

export const useScenario = (scenarioId: string): UseScenario => {
  const { initialising } = useAuthState()
  const {
    scenarioListState: {
      isFetchingList: isFetchingScenarioList,
      isFetchingListItem: isFetchingScenarioListItem,
      scenarios,
      onFetchScenario
    },
    predictionListState: {
      isFetchingList: isFetchingPredictionList,
      isFetchingListItem: isFetchingPredictionListItem,
      predictions,
      onFetchPredictionForScenario,
    },
    predictionSpreadListState: {
      isFetchingListItem: isFetchingPredictionSpreadListItem,
      predictionSpreads,
      onFetchPredictionSpreadForScenario
    }
  } = useScenarioState()

  const isFetchingScenario = isFetchingScenarioList || isFetchingScenarioListItem[scenarioId];
  const isFetchingPrediction = isFetchingPredictionList || isFetchingPredictionListItem[scenarioId]
  const isFetchingPredictionSpread = isFetchingPredictionSpreadListItem[scenarioId]

  const [isCreatingPrediction, setIsCreatingPrediction] = useState(false)
  const [selectedOptionId, setSelectedOptionId] = useState<Maybe<string>>(null)
  const [predictionConfidence, setPredictionConfidence] = useState<number>(0.5)

  const scenario = useMemo(() => {
    return _find(scenarios, ['id', scenarioId])
  }, [scenarios])

  const prediction = useMemo(() => {
    return _find(predictions, ['scenario.id', scenarioId])
  }, [predictions])

  const predictionSpread = useMemo(() => {
    return _find(predictionSpreads, ['scenarioId', scenarioId])
  }, [predictionSpreads])

  const isResolved = scenario ? !!scenario.resolution : false

  useEffect(() => {
    if (!initialising && !scenario && !isFetchingScenario) {
      onFetchScenario(scenarioId)
    }
  }, [initialising, scenario, isFetchingScenario])

  useEffect(() => {
    if (prediction) {
      setSelectedOptionId(prediction.scenarioOption.id)
    }
  }, [prediction, setSelectedOptionId])

  useEffect(() => {
    if (!!prediction || isResolved) {
      if (!predictionSpread && !isFetchingPredictionSpread) {
        onFetchPredictionSpreadForScenario(scenarioId)
      }
    }
  }, [prediction, isResolved, predictionSpread, isFetchingPredictionSpread, onFetchPredictionSpreadForScenario])

  const onSelectOption = useCallback((scenarioOptionId: Maybe<string>): void => {
    setSelectedOptionId(scenarioOptionId || null)
  }, [setSelectedOptionId])

  const onChangePredictionConfidence = useCallback((confidence: number) => {
    setPredictionConfidence(confidence)
  }, [setPredictionConfidence])

  const onMakePrediction = useCallback(async (): Promise<void> => {
    setIsCreatingPrediction(true)
    if (selectedOptionId) {
      await ScenarioService.createPrediction(scenarioId, selectedOptionId, predictionConfidence)
      await Promise.all([
        onFetchPredictionForScenario(scenarioId, true),
        onFetchPredictionSpreadForScenario(scenarioId, true)
      ])
      setIsCreatingPrediction(false)
    }
  }, [selectedOptionId, predictionConfidence, setIsCreatingPrediction])

  return {
    isFetchingScenario: initialising || isFetchingScenario,
    isFetchingPrediction: initialising || isFetchingPrediction,
    isFetchingPredictionSpread: initialising || isFetchingPredictionSpread,
    isCreatingPrediction,
    selectedOptionId: selectedOptionId || '',
    scenarioData: scenario || null,
    prediction: prediction || null,
    predictionSpread: predictionSpread || null,
    predictionConfidence,
    onChangePredictionConfidence,
    onSelectOption,
    onMakePrediction
  }
}